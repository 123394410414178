import { Component, OnInit } from '@angular/core';
//import * as InlineEditor from '@ckeditor/ckeditor5-build-inline/build/ckeditor';
import * as InlineEditor from 'src/assets/ckeditor5/build/ckeditor';
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily.js';
import fontSize from '@ckeditor/ckeditor5-font/src/fontsize.js';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CmsServiceService } from 'src/app/services/cms-service.service';
import { UserModel } from 'src/models/dto/user.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';





@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  agente: UserModel;
  uploadResponse;
  public Editor = InlineEditor;
  imgBox1=new FormControl('');
  form: FormGroup;
  imgBox2=new FormControl('');
  form2: FormGroup;
  imgBox3=new FormControl('');
  form3: FormGroup;
  imgBox4=new FormControl('');
  form4: FormGroup;
  imgBox5=new FormControl('');
  form5: FormGroup;
  imgBox6=new FormControl('');
  form6: FormGroup;
  file_data:any=''
 // public Editor: ClassicEditor;
  imgSrc: any;
  nomeAzienda: any = "IL NOME DELLA TUA AGENZIA";
  public box = {
    titolo: "<h1>La mia agenzia</h1>",
    sottoTitolo: "<p>note varie</p>",
    box1Titolo: "<p>Titolo editabile</p>",
    box1Testo: "<p>subtitle o editabile, scegliamo noi il link mettendo un url statico?.</p>",
    box1Link: "Vai al contenuto",
    box1Img: "./assets/img/box1.jpg",
    box2Titolo: "GAIA EDIZIONI",
    box2Testo: "<p>subtitle o editabile, scegliamo noi il link mettendo un url statico?.</p>",
    box2Link: "Vai al contenuto",
    box2Img: "./assets/logo.png",
    box3Titolo: "<p>Titolo editabile</p>",
    box3Testo: "<p>subtitle o editabile, scegliamo noi il link mettendo un url statico?.</p>",
    box3Link: "Vai al contenuto",
    box3Img: "./assets/img/box1.jpg",
    box4Titolo: "<p>Titolo editabile</p>",
    box4Testo: "<p>subtitle o editabile, scegliamo noi il link mettendo un url statico?.</p>",
    box4Link: "Vai al contenuto",
    box4Img: "./assets/img/box1.jpg",
    box5Titolo: "<p>Titolo editabile</p>",
    box5Testo: "<p>subtitle o editabile, scegliamo noi il link mettendo un url statico?.</p>",
    box5Link: "Vai al contenuto",
    box5Img: "./assets/img/box1.jpg",
    box6Titolo: "<p>Titolo editabile</p>",
    box6Testo: "<p>subtitle o editabile, scegliamo noi il link mettendo un url statico?.</p>",
    box6Link: "Vai al contenuto",
    box6Img: "./assets/img/box1.jpg",
    box1Footer: "Contattaci",
    box2Footer: "Condividi con",
    box3Footer: '<div class="is-social elm-active" style="margin: 25px 0" contenteditable="false"><a href="https://twitter.com/"><i class="icon ion-social-twitter"></i></a><a href="https://www.facebook.com/"><i class="icon ion-social-facebook"></i></a><a href="https://plus.google.com/"><i class="icon ion-social-googleplus"></i></a><a href="mailto:you@example.com"><i class="icon ion-android-drafts icon-active"></i></a></div>',
    box1: true,
    box2: true,
    box3: true,
    box4: true,
    box5: true,
    box6: true,
    generato: '',
    
  }
  

  editorConfig = {
    fontSize: {
      options: [
          9,
          11,
          13,
          'default',
          17,
          19,
          21
      ]
  },
    fontFamily: {
        options: [
            'default',
            'Ubuntu, Arial, sans-serif',
            'Ubuntu Mono, Courier New, Courier, monospace',
            'Roboto, Helvetica Neue, sans-serif'
        ]
    },
    toolbar: {
      items: [
        'Alignment',
        'heading',
        '|',
        'bold',
        'italic',
        'fontFamily',
        'fontSize',
        'FontColor',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        
        'blockQuote',
        'undo',
        'redo'
      ]
    },
    /* image: {
      toolbar: [
        'Image',
        'ImageCaption',
        'ImageResize',
        'ImageStyle',
        'ImageToolbar',
        'ImageUpload',
      ]
    }, */

    simpleUpload: {
      uploadUrl: 'https://e-softweb.net/web/gaia/webservice/upload.php',
       // Enable the XMLHttpRequest.withCredentials property.
       withCredentials: true,

       // Headers sent along with the XMLHttpRequest to the upload server.
       headers: {
           'X-CSRF-TOKEN': 'CSRF-Token',
           Authorization: 'Bearer 123DD222SSS'
       }
    },
    // This value must be kept in sync with the language defined in webpack.config.js.
    language: 'it'
  }; 
  constructor(private formBuilder: FormBuilder, private http: HttpClient, private cmsService: CmsServiceService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    console.log(this.cmsService._currentUser);
    this.agente = this.cmsService._currentUser;
    this.box.generato = this.agente.generato;
    //console.log(this.EditorInline);
    this.imgSrc = './assets/img/box1.jpg';
    this.form = this.formBuilder.group({
      imgBox1: ['']
    });

    this.form2 = this.formBuilder.group({
      imgBox2: ['']
    });

    this.form3 = this.formBuilder.group({
      imgBox3: ['']
    });

    this.form4 = this.formBuilder.group({
      imgBox4: ['']
    });

    this.form5 = this.formBuilder.group({
      imgBox5: ['']
    });

    this.form6 = this.formBuilder.group({
      imgBox6: ['']
    });

    this.getDatiBox();
  }

  getDatiBox() {
    this.cmsService.getDatiBox('').subscribe((res) => {
      this.uploadResponse = res;
        console.log(res);
        if (res.message.code == "ok") {
          let patt = /<a[^>]*href=["']([^"']*)["']/g;
          this.box.titolo = res.data['titolo'];
          this.box.sottoTitolo = res.data['sottoTitolo'];
          this.box.box1 = res.data['box1'];
          this.box.box1Titolo = res.data['box1Titolo'];
          this.box.box1Testo = res.data['box1Testo'];
          this.box.box1Link = res.data['box1Link'];
          if (this.box.box1Link) {
            console.log(this.box.box1Link.match(patt));
           /* while(match=patt.exec(this.box.box1Link)){
                alert(match[1]);
            }*/
          }
          this.box.box1Img = res.data['box1Img'];

          this.box.box2 = res.data['box2'];
          this.box.box2Titolo = res.data['box2Titolo'];
          this.box.box2Testo = res.data['box2Testo'];
          this.box.box2Link = res.data['box2Link'];
          this.box.box2Img = res.data['box2Img'];

          this.box.box3 = res.data['box3'];
          this.box.box3Titolo = res.data['box3Titolo'];
          this.box.box3Testo = res.data['box3Testo'];
          this.box.box3Link = res.data['box3Link'];
          this.box.box3Img = res.data['box3Img'];

          this.box.box4 = res.data['box4'];
          this.box.box4Titolo = res.data['box4Titolo'];
          this.box.box4Testo = res.data['box4Testo'];
          this.box.box4Link = res.data['box4Link'];
          this.box.box4Img = res.data['box4Img'];

          this.box.box5 = res.data['box5'];
          this.box.box5Titolo = res.data['box5Titolo'];
          this.box.box5Testo = res.data['box5Testo'];
          this.box.box5Link = res.data['box5Link'];
          this.box.box5Img = res.data['box5Img'];

          this.box.box6 = res.data['box6'];
          this.box.box6Titolo = res.data['box6Titolo'];
          this.box.box6Testo = res.data['box6Testo'];
          this.box.box6Link = res.data['box6Link'];
          this.box.box6Img = res.data['box6Img'];

          this.box.box1Footer = res.data['box1Footer'];
          this.box.box2Footer = res.data['box2Footer'];
          this.box.box3Footer = res.data['box3Footer'];
        }
        
    },
    (err) => {  
      console.log(err);
    });
  }
    updateImage(event, index) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form.get('imgBox1').setValue(file);

        const formData = new FormData();
        formData.append('avatar', this.form.get('imgBox1').value);

        this.cmsService.uploadFile(formData).subscribe(
          (res) => {
            this.uploadResponse = res;
              console.log(res);
              if (index == '1') {
                this.box.box1Img = this.uploadResponse.url;
              }
          },
          (err) => {  
            console.log(err);
          }
        );
      }
    }

    updateImage2(event, index) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form2.get('imgBox2').setValue(file);

        const formData = new FormData();
        formData.append('avatar', this.form2.get('imgBox2').value);

        this.cmsService.uploadFile(formData).subscribe(
          (res) => {
            this.uploadResponse = res;
              console.log(res);
              if (index == '2') {
                this.box.box2Img = this.uploadResponse.url;
              }
          },
          (err) => {  
            console.log(err);
          }
        );
      }
    }

    updateImage3(event, index) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form3.get('imgBox3').setValue(file);

        const formData = new FormData();
        formData.append('avatar', this.form3.get('imgBox3').value);

        this.cmsService.uploadFile(formData).subscribe(
          (res) => {
            this.uploadResponse = res;
              console.log(res);
              if (index == '3') {
                this.box.box3Img = this.uploadResponse.url;
              }
          },
          (err) => {  
            console.log(err);
          }
        );
      }
    }

    updateImage4(event, index) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form4.get('imgBox4').setValue(file);

        const formData = new FormData();
        formData.append('avatar', this.form4.get('imgBox4').value);

        this.cmsService.uploadFile(formData).subscribe(
          (res) => {
            this.uploadResponse = res;
              console.log(res);
              if (index == '4') {
                this.box.box4Img = this.uploadResponse.url;
              }
          },
          (err) => {  
            console.log(err);
          }
        );
      }
    }

    updateImage5(event, index) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form5.get('imgBox5').setValue(file);

        const formData = new FormData();
        formData.append('avatar', this.form5.get('imgBox5').value);

        this.cmsService.uploadFile(formData).subscribe(
          (res) => {
            this.uploadResponse = res;
              console.log(res);
              if (index == '5') {
                this.box.box5Img = this.uploadResponse.url;
              }
          },
          (err) => {  
            console.log(err);
          }
        );
      }
    }

    updateImage6(event, index) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form6.get('imgBox6').setValue(file);

        const formData = new FormData();
        formData.append('avatar', this.form6.get('imgBox6').value);

        this.cmsService.uploadFile(formData).subscribe(
          (res) => {
            this.uploadResponse = res;
              console.log(res);
              if (index == '6') {
                this.box.box6Img = this.uploadResponse.url;
              }
          },
          (err) => {  
            console.log(err);
          }
        );
      }
    }
  

    salva() {
    this.cmsService.saveEditor(this.box).subscribe(
      (res) => {
        
          console.log(res);
         if (res.message.code == "ok") {
          this.toastr.success(res.message.text, '', {
            timeOut : 2000
          });
         } else {
           this.toastr.error(res.message.text, '', {
            timeOut : 2000
          });

         }
      },
      (err) => {  
        console.log(err);
      }
    );
  }

  openLink() {
    this.cmsService.saveEditor(this.box).subscribe(
      (res) => {
        
          console.log(res);
         if (res.message.code == "ok") {
          this.toastr.success(res.message.text, '', {
            timeOut : 2000
          });
          let url = "https://www.agentiprimaria.it/agente/"+this.box.generato;
          window.open(url, "_blank");
         } else {
           this.toastr.error(res.message.text, '', {
            timeOut : 2000
          });

         }
      },
      (err) => {  
        console.log(err);
      }
    );

    
  }

  logout() {
    this.cmsService._currentUser = new UserModel(null,null,null,null,null);
    this.router.navigateByUrl("/login");
  }

  /* public onReady(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader);
    };
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }
  _onReady(eventData) {

    console.log(eventData);
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      console.log('loader : ', loader)
      console.log(btoa(loader.file));
      return new UploadAdapter(loader);
    };
  }  */

}
