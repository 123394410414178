<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

    <mat-card class="box">
      <mat-card-header>
        <mat-card-title>
            <img src="./assets/logo.png">
        </mat-card-title>
      </mat-card-header>
  
      <form class="example-form">
        <mat-card-content>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Username" [(ngModel)]="dati.username" name="username">
          </mat-form-field>
  
          <mat-form-field class="example-full-width">
            <input type="password" matInput placeholder="Password" [(ngModel)]="dati.password" name="password">
          </mat-form-field>
        </mat-card-content>
        <button mat-stroked-button color="accent" class="btn-block" (click)="accedi()">Accedi</button>
      </form>
    </mat-card>
  
  </div>