import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserModel } from 'src/models/dto/user.model';

@Injectable({
  providedIn: 'root'
})
export class CmsServiceService {
  SERVER_URL: string = "./webservice/upload.php";
  constructor(private httpClient: HttpClient) { }
  public _currentUser: UserModel;

  public uploadFile(data) {
    let uploadURL = `${this.SERVER_URL}`;
    return this.httpClient.post<any>(uploadURL, data);
  }

  public login(accountInfo: any) {
    this._currentUser = new UserModel(null,null,null,null,null);
    console.log(accountInfo);
    return this.httpClient.post<any>('https://www.e-softweb.net/web/gaia/webservice/login.php', accountInfo);
  }

  public saveEditor(datiBox) {
    return this.httpClient.post<any>('https://www.e-softweb.net/web/gaia/webservice/saveBox.php',datiBox);
  }

  public getDatiBox(codiceAgente) {
    let datiAgente = {
      'generato': codiceAgente
    }
    return this.httpClient.post<any>('https://www.e-softweb.net/web/gaia/webservice/getDatiBox.php',datiAgente);
  }

  

}
