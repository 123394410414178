<div class="toolbar" role="banner">
    <div id="left">
        <img
      height="48"
      alt="Gaia Edizioni"
      src="./assets/logo.png"
    />
    </div>
    <div id="center">
        <span class="titoloBook">Benvenuto, {{agente.nome}} {{agente.cognome}}</span>
    </div>
    <div id="right">
        
    <div class="listOpz">
        <ul class="sottomenu">
           
            <li class="itemMenu"><img src="./assets/img/ico-save2.png" (click)="salva()" matTooltip="Salva"></li>
            <li class="itemMenu"><img src="./assets/img/ico-link2.png" (click)="openLink()" matTooltip="Salva e vedi link"></li>
            <li class="itemMenu"><img src="./assets/img/ico-esc.png" (click)="logout()" matTooltip="Esci"></li>
        </ul>

    </div>

    </div>
    
    
      <div class="spacer"></div>
      
</div>
<div class="content" role="main" >

    
    
    <div fxFlex fxLayout="column" fxLayoutGap="10px" style="height: 100vh; margin-top: 80px;
    margin-bottom: 80px;">
    <div class="contentMain">
        <div fxLayout="row wrap">
            <div
            fxFlex="100"
            fxFlex.md="100"
            fxFlex.sm="100"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            class="center"
            >
            
            
            <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.titolo"></ckeditor> 
            <p style="letter-spacing: 2px;"><ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.sottoTitolo"></ckeditor> </p>
            </div>
            <div
            fxFlex="33"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            >
            <!-- cards here -->
            <mat-card class="mat-elevation-z1">
                <form [formGroup]="form" >
                    <img mat-card-image class="imgBox" [src]='box.box1Img' (click)="selectImage1.click()">
                
                    <input type="file" name="imgBox1" (change)="updateImage($event, '1')" style="display: none" #selectImage1>
                </form>
                    <mat-card-title> 
                    <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box1Titolo"></ckeditor> 
                 </mat-card-title>
                <mat-card-content> <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box1Testo"></ckeditor>  </mat-card-content>
                <mat-card-footer>
               
                     <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box1Link"></ckeditor>
                
                </mat-card-footer>
            </mat-card>
            </div>

            <div
            fxFlex="33"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            >
            <!-- cards here -->
            <mat-card class="mat-elevation-z1">
                <form [formGroup]="form2" >
                    <img mat-card-image class="imgBox" [src]='box.box2Img' (click)="selectImage2.click()">
                
                    <input type="file" name="imgBox2" (change)="updateImage2($event, '2')" style="display: none" #selectImage2>
                </form>
               <mat-card-title> <p style="margin-top: 20px;
                margin-bottom: 33px;">{{box.box2Titolo}}</p></mat-card-title>
               <mat-card-content> <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box2Testo"></ckeditor> </mat-card-content>
               <mat-card-footer>
               
                <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box2Link"></ckeditor>
           
           </mat-card-footer>
           </mat-card>
            </div>

            <div
            fxFlex="33"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            >
            <!-- cards here -->
            <mat-card class="mat-elevation-z1">
                <form [formGroup]="form3" >
                    <img mat-card-image class="imgBox" [src]='box.box3Img' (click)="selectImage3.click()">
                
                    <input type="file" name="imgBox3" (change)="updateImage3($event, '3')" style="display: none" #selectImage3>
                </form>
               <mat-card-title> <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box3Titolo"></ckeditor>  </mat-card-title>
               <mat-card-content> <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box3Testo"></ckeditor> </mat-card-content>
               <mat-card-footer>
               
                <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box3Link"></ckeditor>
           
           </mat-card-footer>
           </mat-card>
            </div>


            <div
            fxFlex="33"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            >
            <!-- cards here -->
            <mat-card class="mat-elevation-z1">
                <form [formGroup]="form4" >
                    <img mat-card-image class="imgBox" [src]='box.box4Img' (click)="selectImage4.click()">
                
                    <input type="file" name="imgBox4" (change)="updateImage4($event, '4')" style="display: none" #selectImage4>
                </form>
               <mat-card-title> <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box4Titolo"></ckeditor>  </mat-card-title>
               <mat-card-content> <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box4Testo"></ckeditor> </mat-card-content>
               <mat-card-footer>
               
                <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box4Link"></ckeditor>
           
           </mat-card-footer>
           </mat-card>
            </div>


            <div
            fxFlex="33"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            >
            <!-- cards here -->
            <mat-card class="mat-elevation-z1">
                <form [formGroup]="form5" >
                    <img mat-card-image class="imgBox" [src]='box.box5Img' (click)="selectImage5.click()">
                
                    <input type="file" name="imgBox5" (change)="updateImage5($event, '5')" style="display: none" #selectImage5>
                </form>
               <mat-card-title> <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box5Titolo"></ckeditor>  </mat-card-title>
               <mat-card-content> <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box5Testo"></ckeditor> </mat-card-content>
               <mat-card-footer>
               
                <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box5Link"></ckeditor>
           
           </mat-card-footer>
           </mat-card>
            </div>

            <div
            fxFlex="33"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            >
            <!-- cards here -->
            <mat-card class="mat-elevation-z1">
                <form [formGroup]="form6" >
                    <img mat-card-image class="imgBox" [src]='box.box6Img' (click)="selectImage6.click()">
                
                    <input type="file" name="imgBox6" (change)="updateImage6($event, '6')" style="display: none" #selectImage6>
                </form>
                <mat-card-title> <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box6Titolo"></ckeditor>  </mat-card-title>
                <mat-card-content> <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box6Testo"></ckeditor> </mat-card-content>
                <mat-card-footer>
               
                    <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box6Link"></ckeditor>
               
               </mat-card-footer>
            </mat-card>
            </div>

            

            
        </div>
    </div>
        <div class="break"></div>
    <div class="footer">
        
        
            <div fxLayout="row wrap" style="height: 150px;">
                <div
                fxFlex="33"
                fxFlex.md="33"
                fxFlex.sm="100"
                fxFlex.xs="100"
                fxLayout="column"
                style="padding: 5px;"
                class="center"
                >
                
                <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box1Footer"></ckeditor>
                </div>
            
                <div
                fxFlex="33"
                fxFlex.md="33"
                fxFlex.sm="100"
                fxFlex.xs="100"
                fxLayout="column"
                style="padding: 5px;"
                class="center"
                >
                
                <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box2Footer"></ckeditor>
                </div>
                <div
                fxFlex="33"
                fxFlex.md="33"
                fxFlex.sm="100"
                fxFlex.xs="100"
                fxLayout="column"
                style="padding: 5px;"
                class="center"
                >
                
                <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="box.box3Footer"></ckeditor>
            </div>
        </div>
    
    
    </div>
    

</div>


<!-- <nav class="pagination" role="navigation" style="right: 0px;"> 
    <div class="pag-nav-container">
        
    </div>
</nav> -->