import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CmsServiceService } from 'src/app/services/cms-service.service';
import { UserModel } from 'src/models/dto/user.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  dati = {
    username: '',
    password: ''
  }
  constructor(private cmsService: CmsServiceService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  accedi() {
    this.cmsService._currentUser = new UserModel(null,null,null,null,null);
    this.cmsService.login(this.dati).subscribe(
      (res) => {
        
          console.log(res);
          if (res.message.code == 'ok') {
            this.cmsService._currentUser.nome = res.data.nome;
            this.cmsService._currentUser.cognome = res.data.cognome;
            this.cmsService._currentUser.email = res.data.email;
            this.cmsService._currentUser.generato = res.data.generato;

            this.router.navigateByUrl("/home");
          } else {
            this.toastr.error(res.message.text, '', {
              timeOut : 2000
            });
          }
          
      },
      (err) => {  
        console.log(err);
      }
    );
  }
}
