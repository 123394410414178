

export interface cartelleUtente {
    idpaz: any;
    cartella: string,
    iniziali: string,
    actid: string
  }

export class UserModel  {

    private _id: string; 
    
    
    
    private _nome: string;
    
     
    private _cognome: string;

    private _generato: string;
    
     
    private _email: string;

    
   

    constructor(id: string, nome:  string,  cognome: string, email: string, generato: string) {
        
        this._nome = nome;
		this._id = id;
		this._cognome = cognome;
		this._email = email;
        this._generato = generato;
		
          

	}

     

    

      public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
      public get nome(): string {
        return this._nome;
    }
    public set nome(value: string) {
        this._nome = value;
    }
    public get cognome(): string {
        return this._cognome;
    }
    public set cognome(value: string) {
        this._cognome = value;
    }
    public get email(): string {
        return this._email;
    }
    public set email(value: string) {
        this._email = value;
    }


    public get generato(): string {
        return this._generato;
    }
    public set generato(value: string) {
        this._generato = value;
    }
    
    
}