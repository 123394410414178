import { Component, OnInit, Pipe } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsServiceService } from 'src/app/services/cms-service.service';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-agente',
  templateUrl: './agente.component.html',
  styleUrls: ['./agente.component.scss']
})
export class AgenteComponent implements OnInit {
  testHtml: any;
  public box : {
    titolo: any,
    sottoTitolo: any,
    box1Titolo: any,
    box1Testo: any,
    box1Link: any,
    box1Img: any,
    box2Titolo: any,
    box2Testo: any,
    box2Link: any,
    box2Img: any,
    box3Titolo: any,
    box3Testo: any,
    box3Link: any,
    box3Img: any,
    box4Titolo: any,
    box4Testo: any,
    box4Link: any,
    box4Img: any,
    box5Titolo: any,
    box5Testo: any,
    box5Link: any,
    box5Img: any,
    box6Titolo: any,
    box6Testo: any,
    box6Link: any,
    box6Img: any,
    box1Footer: any,
    box2Footer: any,
    box3Footer: any,
    box1: any,
    box2: boolean,
    box3: any,
    box4: any,
    box5: any,
    box6: any,
    boxBanner: any,
    generato: any,
    
  } = {
    titolo: "",
    sottoTitolo: "",
    box1Titolo: "",
    box1Testo: "",
    box1Link: "",
    box1Img: "",
    box2Titolo: "GAIA EDIZIONI",
    box2Testo: "",
    box2Link: "",
    box2Img: "",
    box3Titolo: "",
    box3Testo: "",
    box3Link: "",
    box3Img: "",
    box4Titolo: "",
    box4Testo: "",
    box4Link: "",
    box4Img: "",
    box5Titolo: "",
    box5Testo: "",
    box5Link: "",
    box5Img: "",
    box6Titolo: "",
    box6Testo: "",
    box6Link: "",
    box6Img: "",
    box1Footer: "Contattaci",
    box2Footer: "",
    box3Footer: '',
    box1: '',
    box2: true,
    box3: '',
    box4: '',
    box5: '',
    box6: '',
    boxBanner: '',
    generato: '',
    
  }
  box1ImgLink: any = '';
  box2ImgLink: any = '';
  box3ImgLink: any = '';
  box4ImgLink: any = '';
  box5ImgLink: any = '';
  box6ImgLink: any = '';
  constructor(private activatedRoute: ActivatedRoute, private cmsService: CmsServiceService, protected sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.box.generato = this.activatedRoute.snapshot.paramMap.get('codiceAgente');
    this.getDatiBox();
  }

  getDatiBox() {
    this.cmsService.getDatiBox(this.box.generato).subscribe((res) => {
      
        console.log(res);
        if (res.message.code == "ok") {
          
          let patt = /<a[^>]*href=["']([^"']*)["']/g;
          this.box.titolo = this.sanitizer.bypassSecurityTrustHtml(res.data['titolo']);
          this.box.sottoTitolo = this.sanitizer.bypassSecurityTrustHtml(res.data['sottoTitolo']);
          this.box.box1 = res.data['box1'];
          this.box.box1Titolo = this.sanitizer.bypassSecurityTrustHtml(res.data['box1Titolo']);
          this.box.box1Testo = this.sanitizer.bypassSecurityTrustHtml(res.data['box1Testo']);
          this.box.box1Link = this.sanitizer.bypassSecurityTrustHtml(res.data['box1Link']);
          this.box.box1Img = res.data['box1Img'];
          if (this.box.box1Link) {
           
            let match;
            while(match=patt.exec(res.data['box1Link'])){
                this.box1ImgLink = match[1];
            }
          }
          this.box.box2 = res.data['box2'];
          this.box.box2Titolo = res.data['box2Titolo'];
          this.box.box2Testo = this.sanitizer.bypassSecurityTrustHtml(res.data['box2Testo']);
          this.box.box2Link = this.sanitizer.bypassSecurityTrustHtml(res.data['box2Link']);
          this.box.box2Img = res.data['box2Img'];
          if (this.box.box2Link) {
            
            let match;
            while(match=patt.exec(res.data['box2Link'])){
                this.box2ImgLink = match[1];
            }
          }
          this.box.box3 = res.data['box3'];
          this.box.box3Titolo = this.sanitizer.bypassSecurityTrustHtml(res.data['box3Titolo']);
          this.box.box3Testo = this.sanitizer.bypassSecurityTrustHtml(res.data['box3Testo']);
          this.box.box3Link = this.sanitizer.bypassSecurityTrustHtml(res.data['box3Link']);
          this.box.box3Img = res.data['box3Img'];
          if (this.box.box3Link) {
           
            let match;
            while(match=patt.exec(res.data['box3Link'])){
                this.box3ImgLink = match[1];
            }
          }
          this.box.box4 = res.data['box4'];
          this.box.box4Titolo = this.sanitizer.bypassSecurityTrustHtml(res.data['box4Titolo']);
          this.box.box4Testo = this.sanitizer.bypassSecurityTrustHtml(res.data['box4Testo']);
          this.box.box4Link = this.sanitizer.bypassSecurityTrustHtml(res.data['box4Link']);
          this.box.box4Img = res.data['box4Img'];
          if (this.box.box4Link) {
            
            let match;
            while(match=patt.exec(res.data['box4Link'])){
                this.box4ImgLink = match[1];
            }
          }
          this.box.box5 = res.data['box5'];
          this.box.box5Titolo = this.sanitizer.bypassSecurityTrustHtml(res.data['box5Titolo']);
          this.box.box5Testo = this.sanitizer.bypassSecurityTrustHtml(res.data['box5Testo']);
          this.box.box5Link = this.sanitizer.bypassSecurityTrustHtml(res.data['box5Link']);
          this.box.box5Img = res.data['box5Img'];
          if (this.box.box5Link) {
            
            let match;
            while(match=patt.exec(res.data['box5Link'])){
                this.box5ImgLink = match[1];
            }
          }
          this.box.box6 = res.data['box6'];
          this.box.box6Titolo = this.sanitizer.bypassSecurityTrustHtml(res.data['box6Titolo']);
          this.box.box6Testo = this.sanitizer.bypassSecurityTrustHtml(res.data['box6Testo']);
          this.box.box6Link = this.sanitizer.bypassSecurityTrustHtml(res.data['box6Link']);
          this.box.box6Img = res.data['box6Img'];
          if (this.box.box6Link != "" && this.box.box6Link != "null" && this.box.box6Link != null) {
            
            let match;
            while(match=patt.exec(res.data['box6Link'])){
                this.box6ImgLink = match[1];
            }
          }
          this.box.box1Footer = this.sanitizer.bypassSecurityTrustHtml(res.data['box1Footer']);
          this.box.box2Footer = this.sanitizer.bypassSecurityTrustHtml(res.data['box2Footer']);
          this.box.box3Footer = this.sanitizer.bypassSecurityTrustHtml(res.data['box3Footer']);
          this.box.boxBanner = res.data['boxBanner'];
        }
        
    },
    (err) => {  
      console.log(err);
    });
  }

  /*public transform(value: any, type: string): any {
    const sanitizedContent = DOMPurify.sanitize(value);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);

 } */
  openUrl(box) {
    if (box == 1) {
      if (this.box1ImgLink) {
        let url = this.box1ImgLink;
          window.open(url, "_blank");
      }  
      
    } else if (box == 2) {
      if (this.box2ImgLink) {
        let url = this.box2ImgLink;
          window.open(url, "_blank");
      }  
      
    } else if (box == 3) {
      if (this.box3ImgLink) {
        let url = this.box3ImgLink;
          window.open(url, "_blank");
      }  
      
    } else if (box == 4) {
      if (this.box4ImgLink) {
        let url = this.box4ImgLink;
          window.open(url, "_blank");
      }  
      
    } else if (box == 5) {
      if (this.box5ImgLink) {
        let url = this.box5ImgLink;
          window.open(url, "_blank");
      }  
      
    } else if (box == 6) {
      if (this.box6ImgLink) {
        let url = this.box6ImgLink;
          window.open(url, "_blank");
      }  
      
    }  
  }

}
