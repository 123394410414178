
<div class="content" role="main" >

    
    
    <div fxFlex fxLayout="column" fxLayoutGap="10px" style="height: 100vh; margin-top: 80px;
    margin-bottom: 80px;">
    <div class="contentMain">
        <div fxLayout="row wrap">
            <div
            fxFlex="100"
            fxFlex.md="100"
            fxFlex.sm="100"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            class="center"
            >
            
            <span  [innerHTML]="box.titolo"></span>
            
            
            </div>

            <div
            fxFlex="100"
            fxFlex.md="100"
            fxFlex.sm="100"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            class="center"
            >
            
                <img mat-card-image class="imgBox" [src]='box.boxBanner' *ngIf="box.boxBanner != ''">
            
            </div>

            <div
            fxFlex="100"
            fxFlex.md="100"
            fxFlex.sm="100"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            class="center"
            >
                <p style="letter-spacing: 2px;" [innerHTML]="box.sottoTitolo"></p>
            </div>
            <div
            fxFlex="33"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            >
            <!-- cards here -->
            <mat-card class="mat-elevation-z1">
                
                    <img mat-card-image class="imgBox" [src]='box.box1Img' (click)="openUrl('1')">
                    <mat-card-title> 
                        <span  [innerHTML]="box.box1Titolo"></span>
                 </mat-card-title>
                <mat-card-content>
                    <span  [innerHTML]="box.box1Testo"></span> 
                 </mat-card-content>
                <mat-card-footer>
                    <span  [innerHTML]="box.box1Link"></span>
                    
                
                </mat-card-footer>
            </mat-card>
            </div>


           
            <div
            fxFlex="33"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            >
            <!-- cards here -->
            <mat-card class="mat-elevation-z1">
                
                    <img mat-card-image class="imgBox" [src]='box.box2Img' (click)="openUrl('2')">
                
               <mat-card-title> <p style="text-align: center;">{{box.box2Titolo}}</p></mat-card-title>
               <mat-card-content> 
                <span  [innerHTML]="box.box2Testo"></span>
                 </mat-card-content>
               <mat-card-footer>
                <span  [innerHTML]="box.box2Link"></span>
                
           </mat-card-footer>
           </mat-card>
            </div>

            <div
            fxFlex="33"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            >
            <!-- cards here -->
            <mat-card class="mat-elevation-z1">
                
                    <img mat-card-image class="imgBox" [src]='box.box3Img' (click)="openUrl('3')" >
                
               <mat-card-title> <span  [innerHTML]="box.box3Titolo"></span>   </mat-card-title>
               <mat-card-content> <span  [innerHTML]="box.box3Testo"></span> </mat-card-content>
               <mat-card-footer>
                <span  [innerHTML]="box.box3Link"></span>
               
           </mat-card-footer>
           </mat-card>
            </div>


            <div
            fxFlex="33"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            >
            <!-- cards here -->
            <mat-card class="mat-elevation-z1">
                
                    <img mat-card-image class="imgBox" [src]='box.box4Img' (click)="openUrl('4')" >
                
                   
               <mat-card-title> 
                <span  [innerHTML]="box.box4Titolo"></span>
                 </mat-card-title>
               <mat-card-content> 
                <span  [innerHTML]="box.box4Testo"></span>
                </mat-card-content>
               <mat-card-footer>
                <span  [innerHTML]="box.box4Link"></span>
                
           </mat-card-footer>
           </mat-card>
            </div>


            <div
            fxFlex="33"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            >
            <!-- cards here -->
            <mat-card class="mat-elevation-z1">
                
                    <img mat-card-image class="imgBox" [src]='box.box5Img' (click)="openUrl('5')">
                
               <mat-card-title> 
                <span  [innerHTML]="box.box5Titolo"></span>
                 </mat-card-title>
               <mat-card-content> 
                <span  [innerHTML]="box.box5Testo"></span>
                 </mat-card-content>
               <mat-card-footer>
                <span  [innerHTML]="box.box5Link"></span>
               
           
           </mat-card-footer>
           </mat-card>
            </div> 

            <div
            fxFlex="33"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;"
            >
            <!-- cards here -->
            <mat-card class="mat-elevation-z1">
                    <img mat-card-image class="imgBox" [src]='box.box6Img' (click)="openUrl('6')">
                
                <mat-card-title> 
                    <span  [innerHTML]="box.box6Titolo"></span>
                      </mat-card-title>
                <mat-card-content> 
                    <span  [innerHTML]="box.box6Testo"></span>
                     </mat-card-content>
                <mat-card-footer>
                    <span  [innerHTML]="box.box6Link"></span>
                   
               
               </mat-card-footer>
            </mat-card>
            </div>

            

            
        </div>
    </div>
        <div class="break"></div>
    <div class="footer">
        
        
            <div fxLayout="row wrap" style="height: 150px;">
                <div
                fxFlex="33"
                fxFlex.md="33"
                fxFlex.sm="100"
                fxFlex.xs="100"
                fxLayout="column"
                style="padding: 5px;"
                class="center"
                >
                <span  [innerHTML]="box.box1Footer"></span>
                
                </div>
            
                <div
                fxFlex="33"
                fxFlex.md="33"
                fxFlex.sm="100"
                fxFlex.xs="100"
                fxLayout="column"
                style="padding: 5px;"
                class="center"
                >
                <span  [innerHTML]="box.box2Footer"></span>
                
                </div>
                <div
                fxFlex="33"
                fxFlex.md="33"
                fxFlex.sm="100"
                fxFlex.xs="100"
                fxLayout="column"
                style="padding: 5px;"
                class="center"
                >
                <span  [innerHTML]="box.box3Footer"></span>
               
            </div>
        </div>
    
    
    </div>
    

</div>


<!-- <nav class="pagination" role="navigation" style="right: 0px;"> 
    <div class="pag-nav-container">
        
    </div>
</nav> -->